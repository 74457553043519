import * as React from 'react';
import Button from '@material-ui/core/Button';
import rejectButton from './RejectButton.styles';

export interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const RejectButton = (props: Props) => {
  return (
    <Button
      variant="outlined"
      className={rejectButton}
      onClick={() => {
        props.onClick();
      }}
      disabled={props.disabled}
      data-qa="reject-button"
    >
      <i className="fal fa-fw fa-2x fa-times" />
    </Button>
  );
};

export default RejectButton;
