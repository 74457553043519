import * as React from 'react';
import Button from '@material-ui/core/Button';
import acceptButton from './AcceptButton.styles';

export interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const AcceptButton = (props: Props) => {
  return (
    <Button
      variant="contained"
      className={acceptButton}
      onClick={() => {
        props.onClick();
      }}
      data-qa="accept-button"
      disabled={props.disabled}
    >
      <i className="fal fa-2x fa-check" />
    </Button>
  );
};

export default AcceptButton;
