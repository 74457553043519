import { isNil, flatMap } from 'lodash';

import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  MasterDetailChartConfig,
  MasterDetailChartMetrics,
} from 'src/components/MasterDetailRenderer/MasterDetailRenderer';
import { axesDefaults } from 'src/components/Visualize/Visualize.utils';
import { Renderer } from 'src/utils/Domain/Renderer';
import { BasicTenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { AxisLabelsFormatterContextObject, AxisOptions, Options, Point, SeriesOptions } from 'highcharts';

function getXaxisLabels(dataIndex: string, data: BasicPivotItem[]) {
  return data.map((item) => item[dataIndex]);
}

function generateSeriesOptions(metrics: MasterDetailChartMetrics, data: BasicPivotItem[]): SeriesOptions[] {
  const mergedSeries = flatMap<MasterDetailChartMetrics, SeriesOptions>(metrics, (axisMetrics, key) => {
    if (isNil(axisMetrics)) {
      return [];
    }

    return axisMetrics.map((metric) => {
      const isPercentFormatted = metric.renderer === 'percent';

      // this is usually a line graph, but we potentially allow (via chartType) any chart type string through
      return {
        type: metric.chartType || 'line',
        name: metric.text || metric.dataIndex,
        description: metric.text, // used for screenreaders only
        data: data.map((item) => {
          return isPercentFormatted ? item[metric.dataIndex] * 100 : item[metric.dataIndex];
        }),
        color: metric?.color,
        tooltip: {
          pointFormatter: function(this: Point) {
            const seriesName = this.series.name;
            const category = this.category;
            // may need to unformat percent value if renderer is percent for proper display in tooltip (e.g. 39.56 -> 0.3956)
            const renderedPointValue =
              metric?.renderer && this.y
                ? Renderer[metric.renderer](isPercentFormatted ? this.y / 100 : this.y)
                : this.y;
            return `${category} <br /> ${seriesName} : <b>${renderedPointValue}</b>`;
          },
        },
        yAxis: key === 'left' ? 0 : 1,
      };
    });
  });

  return mergedSeries;
}

function hasPercentRenderer(metrics: BasicTenantConfigViewItem[]) {
  return metrics.some((metric) => metric.renderer === 'percent');
}

function generateYaxisOptions(configMetrics: MasterDetailChartMetrics): AxisOptions | AxisOptions[] {
  if (isNil(configMetrics.right)) {
    return {
      ...axesDefaults,
    };
  }

  // for master detail, assuming we may have more than 2 metrics per side (left/right),
  // so leaving off text descriptions for now
  return [
    {
      ...axesDefaults,
      ...(hasPercentRenderer(configMetrics.left) ? { min: 0, max: 100 } : undefined),
    },
    {
      ...axesDefaults,
      opposite: true,
      ...(hasPercentRenderer(configMetrics.right) ? { min: 0, max: 100 } : undefined),
    },
  ];
}

export function generateMasterDetailChartConfig(
  parentId: string,
  config: MasterDetailChartConfig,
  data: BasicPivotItem[]
): Options {
  const xAxisLabels = getXaxisLabels(config.xAxisDataIndex, data);
  const series = generateSeriesOptions(config.metrics, data);
  const yAxis = generateYaxisOptions(config.metrics);

  return {
    credits: { enabled: false },
    chart: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
      text: `${config.chartTitle}`,
    },
    yAxis,
    xAxis: {
      categories: xAxisLabels,
      title: {
        text: '',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        formatter: function(this: AxisLabelsFormatterContextObject) {
          return `<span style="font-style: italic;">${this.value}</span>`;
        },
      },
    },
    // @ts-ignore
    series,
  };
}
