import React from 'react';
import { Button, Icon, StyledComponentProps, Tooltip, withStyles } from '@material-ui/core';
import { classes, keyframes, style } from 'typestyle';
import { isNil } from 'lodash';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';

const styles = () => {
  return {
    root: {
      height: '42px',
      padding: '0 0.5rem',
      textTransform: 'none',
      fontFamily: 'inherit',
    } as CSSProperties,
  };
};

const textIconStyle = style({
  marginLeft: '0.5rem',
});

// needs the fa-fw (fixed width) style that may be overwritten by root class
const iconStyle = style({
  width: '1.25em !important',
});

const heartbeatAnimation = keyframes({
  '0%': { transform: 'scale(1)' },
  '25%': { transform: 'scale(1.3)' },
  '50%': { transform: 'scale(1)' },
  '75%': { transform: 'scale(1.3)' },
  '100%': { transform: 'scale(1)' },
});

const loadingStyle = style({
  animationName: heartbeatAnimation,
  animationDuration: '2.5s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
});

const rootNoText = style({
  padding: '0 0.5rem !important',
  minWidth: '40px !important',
});

/** Used as a normal button */
type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

/** Used as a download button acting as a link */
type ButtonLinkProps = {
  href?: string;
};
type BaseProps = StyledComponentProps & {
  iconClass: string;
  text?: string;
  buttonDataQa?: string;
  iconDataQa?: string;
  defaultsPresent?: boolean;
  style?: { [s: string]: any };
  tooltipText?: string;
  isLoading?: boolean;
};
type Props = BaseProps & (ButtonProps | ButtonLinkProps);

const SubheaderQuickActionButton = ({
  text,
  defaultsPresent = true,
  iconClass,
  iconDataQa,
  buttonDataQa,
  tooltipText,
  isLoading = false,
  ...restProps
}: Props) => {
  const iconClasses = classes(
    iconClass,
    'fa-fw',
    isNil(text) ? iconStyle : textIconStyle,
    isLoading ? loadingStyle : ''
  );

  const buttonContents = (
    <React.Fragment>
      {text}
      <Icon className={iconClasses} data-qa={iconDataQa} fontSize="small" />
    </React.Fragment>
  );

  const buttonClasses = isNil(text) ? rootNoText : restProps.classes?.root;
  const buttonContent =
    'href' in restProps ? (
      <Button
        className={buttonClasses}
        data-qa={buttonDataQa}
        size={'large'}
        color={defaultsPresent ? 'primary' : 'secondary'}
        href={restProps.href ? restProps.href : ''}
        target={restProps.href ? '_blank' : undefined}
        disabled={isLoading}
        {...restProps}
      >
        {buttonContents}
      </Button>
    ) : (
      <Tooltip title={tooltipText || ''}>
        <Button
          className={buttonClasses}
          data-qa={buttonDataQa}
          size={'large'}
          color={defaultsPresent ? 'primary' : 'secondary'}
          disabled={isLoading}
          {...restProps}
        >
          {buttonContents}
        </Button>
      </Tooltip>
    );

  return <MuiThemeProvider theme={muiTheme}>{buttonContent} </MuiThemeProvider>;
};

export default withStyles(styles)(SubheaderQuickActionButton);
