import React from 'react';
import type { TrendBubblesConfig } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import HighchartsReact from 'highcharts-react-official';
import { isNil } from 'lodash';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
import Renderer from 'src/utils/Domain/Renderer';

export interface TrendBubblesProps {
  config: TrendBubblesConfig;
  data: BasicPivotItem[];
  groupByDataIndex: string;
}

export const TrendBubbles = ({ data, config, groupByDataIndex }: TrendBubblesProps) => {
  const highchartsConfig: Highcharts.Options = {
    credits: { enabled: false },
    title: {
      text: ''
    },
    chart: {
      ...config.chartOptions.chart,
      height: '100%',
    },
    tooltip: {
      // blatantly stolen from geotrends
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const bubbleConfig = config.chartOptions.bubble;
        // parentNode's don't have a name (they assume series name)...no I don't know why >:(
        if (!isNil(bubbleConfig) && !isNil(this.point.name)) {
          const popoverTitle = bubbleConfig.text ? bubbleConfig.text : bubbleConfig.dataIndex;
          const dataRenderer = bubbleConfig ? bubbleConfig.renderer : 'usMoneyNoCents';
          return this.point.name + `<br/> ${popoverTitle} ` + Renderer[dataRenderer](this.point.y);
        } else {
          return this.series.name;
        }
      },
    },
    // TODO: align our zod type with the expected highcharts type here
    plotOptions: config.chartOptions.plotOptions as unknown as Highcharts.PlotOptions,
    series: data.map((group) => {
      return {
        name: group[groupByDataIndex],
        data: group.children.map((row) => {
          return {
            name: row[config.graph.nameDataIndex],
            value: row[config.graph.dataIndex]
          }
        })
      }
    }) as Highcharts.SeriesPackedbubbleOptions[] // TODO type this better so highcharts isn't mad
  };
  return (
    <div data-qa ="trend-bubbles">
      <HighchartsReact highcharts={Highcharts} key={'trendBubbles'} options={highchartsConfig} immutable={true} />
    </div>
  );
};
