import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Axios from 'src/services/axios';
import ImageCropper from 'src/components/ImageCropper/ImageCropper/ImageCropper';
import styles from './ImageCropperModal.styles';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';
import { classes } from 'typestyle';

type LocalAxiosResponse = {
  data: { data: { fileName: string } };
};

type Props = {
  open: boolean;
  passedImage: string;
  returnImage: (image: string) => void;
  closeModal: () => void;
};

type State = {
  uploadedImage: string | null;
  isDragging: boolean;
};

export default class ImageCropperModal extends React.Component<Props, State> {
  imageCropper: React.RefObject<ImageCropper> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      uploadedImage: null,
      isDragging: false,
    };
  }

  handleCancel = () => {
    const { closeModal } = this.props;
    this.setState({ uploadedImage: null }, closeModal);
  };

  handleSave = async () => {
    try {
      if (this.imageCropper.current) {
        const newImageBlob = await this.imageCropper.current.getCroppedImage();
        const formData = new FormData();
        if (!isNil(newImageBlob)) {
          formData.append('file', newImageBlob);
          Axios.post('api/asset/upload', formData).then((uploadResp: LocalAxiosResponse) => {
            const fileName = uploadResp.data.data.fileName;
            return Axios.post('/api/asset/saveNoCrop', {
              id: fileName,
            }).then((resp) => {
              this.props.returnImage(resp.data.data);
            });
          });
        }
      }
    } catch (_error) {
      toast.error('An Error Occurred Trying To Save Image');
    }
  };

  handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ uploadedImage: reader.result as string });
    };
    reader.readAsDataURL(file);
  };

  handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.handleImageUpload(e.target.files[0]);
    }
  };

  handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: true });
  };
  handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
  };
  handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      this.handleImageUpload(files[0]);
    }
  };

  render() {
    const { open } = this.props;
    const { uploadedImage, isDragging } = this.state;
    const image = uploadedImage || this.props.passedImage;

    return (
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason === 'escapeKeyDown') {
            this.handleCancel();
          }
        }}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        <div className={styles.titleContainer}>
          <span className={styles.title}>Crop Image</span>
        </div>
        <div className="input-file-container">
          <input type="file" onChange={this.handleFileInputChange} />
        </div>

        <div className={styles.dialogBody}>
          {isDragging ? (
            <div
              style={{ cursor: isDragging && 'grab' }}
              className={classes(styles.dropContainer, isDragging && styles.dragActive)}
            >
              Release to upload
            </div>
          ) : (
            <div className={styles.cropperContainer}>
              <ImageCropper ref={this.imageCropper} image={image} />
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonSave}>
            <span className={styles.buttonText} onClick={this.handleCancel}>
              Cancel
            </span>
          </div>
          <div className={styles.buttonSave} onClick={this.handleSave}>
            <span className={styles.buttonText}>SAVE</span>
          </div>
        </div>
      </Dialog>
    );
  }
}
