import { classes } from 'typestyle';
import React from 'react';
import { findIndex, isEmpty, isNil, noop, nth } from 'lodash';
import BottomBar from './BottomBar';
import SubheaderDropdown from './SubheaderDropdown';
import { containerStyle, getFiltersStyle } from './Subheader.styles';
import ViewConfiguratorModal from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { BackgroundDataLoading } from '../BackgroundDataLoading/BackgroundDataLoading';
import { Favorites } from './Favorites/Favorites';
import { SubheaderFilters } from './SubheaderFilters';
import { SortBySlice } from './Subheader.slice';
import SubheaderQuickActionButton from './SubheaderQuickActionButton';
import { SubheaderProps, SubheaderState } from 'src/components/Subheader/Subheader.types';
import { MassEdit as MassEditV2 } from 'src/components/MassEdit/MassEditv2';
import { MassEdit } from 'src/components/MassEdit/MassEdit';
import { ConfigurablePostAction } from 'src/components/ConfigurableGrid/utils/ConfigurablePostActions';
import { gridActionButtons } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { ViewDataState } from 'src/types/Domain';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { AssortmentFavoriteType } from './Favorites/Favorites.types';
import { getViewNameFromPath } from 'src/state/breadcrumbs/Breadcrumbs.utils';
import { Link } from '@material-ui/core';
import { getLatestAccessToken } from 'src/services/auth/platform';
import queryString from 'query-string';

function showSortArrow(sortBy: SortBySlice): boolean {
  const hasSortOptions = sortBy.options.length > 0;
  const hasEmptySortOption = hasSortOptions && sortBy.options[0].text === '';
  const isEmptyOptionSelected = hasEmptySortOption && sortBy.selection === 0;
  const hasEmptyOptionOnly = hasEmptySortOption && sortBy.options.length === 1;
  const shouldHideSortArrow = !hasSortOptions || hasEmptyOptionOnly || isEmptyOptionSelected;
  return !shouldHideSortArrow;
}

function generateDownloadLinkWithToken(href: string): string {
  const latestToken = getLatestAccessToken();
  const accessToken: string = latestToken != null ? latestToken : '';
  const encodedToken = encodeURIComponent(accessToken);
  const { url, query } = queryString.parseUrl(href);

  const stringifiedUrl = queryString.stringifyUrl({
    url,
    query: {
      ...query,
      token: encodedToken,
    },
  });

  return stringifiedUrl;
}

export default class Subheader extends React.Component<SubheaderProps, SubheaderState> {
  viewConfiguratorRef: React.RefObject<ViewConfiguratorModal> = React.createRef();

  constructor(props: SubheaderProps) {
    super(props);
    this.state = {
      loadingViewDefns: false,
      filtersVisible: false,
      filtersPopoverVisible: false,
    };
  }

  componentDidMount() {
    const { getViewDefns, sortByDefn, groupByDefn, pareDownDefn, countLimitDefn } = this.props;
    const defns: (string | null)[] = [];
    defns.push(groupByDefn || null);
    defns.push(sortByDefn || null);
    defns.push(countLimitDefn || null);
    defns.push(pareDownDefn || null);
    getViewDefns(defns);
  }

  toggleFilters = () => {
    this.setState({
      filtersVisible: !this.state.filtersVisible,
    });
  };

  toggleFiltersPopover = () => {
    this.setState({
      filtersPopoverVisible: !this.state.filtersPopoverVisible,
    });
  };
  handleUndoClick = () => {
    this.props.onOpenUndoPanel();
  };

  onFavoriteSave = async () => {
    // since the viewConfigurator stores the modifications in localState
    // using ref to trigger same sequence that orders columns as they appear
    // in the grid when the viewConfigurator is opened
    await this.viewConfiguratorRef.current?.onOpen();
    await this.viewConfiguratorRef.current?.onSubmit();
    await this.viewConfiguratorRef.current?.onClose();
    return Promise.resolve((this.viewConfiguratorRef.current?.state.viewConfig as unknown) as TenantConfigViewData);
  };

  render() {
    const {
      subheader,
      configureOptions,
      extraDropdowns,
      extraActionButtons,
      extraLegacyActionButtons,
      viewConfigurator,
      showDateRange,
      gridFilterModel,
      gridSortModel,
      gridColumnState,
      resetGridModel,
      showUndoBtn,
      favoriteType = AssortmentFavoriteType.notImplemented,
      favoriteDefnId = '',
      favoriteViewDefnUpdater = noop,
      activeCrumbPath,
      resetCrumbs,
    } = this.props;
    const { groupBy, sortBy, pareDown, countLimit, countLimitOptions } = subheader;
    const extraDropdownsElements =
      extraDropdowns && extraDropdowns.length > 0
        ? extraDropdowns.map((dropdown) => <SubheaderDropdown key={dropdown.label} {...dropdown} />)
        : [];

    // Filter inputs

    const flowStatusInputs = {
      showFlowStatus: this.props.showFlowStatus,
      flowStatus: subheader.flowStatus,
      flowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateFlowStatus: this.props.updateFlowStatus,
    };
    const altFlowStatusInputs = {
      showAltFlowStatus: this.props.showAlternateFlowStatus,
      altFlowStatus: subheader.altFlowStatus,
      altFlowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateAltFlowStatus: this.props.updateAltFlowStatus,
    };
    const searchInputs = {
      showSearch: this.props.showSearch,
      search: subheader.search,
      searchReturn: this.props.searchReturn,
      updateSearchString: this.props.updateSearchString,
    };
    const altSearchInputs = {
      showAltSearch: this.props.showAlternateSearch,
      altSearch: subheader.altSearch,
      searchReturn: this.props.searchReturn,
      updateAltSearchString: this.props.updateAltSearchString,
    };
    const pareDownInputs = {
      showPareDown: !(
        !subheader.pareDownDefn ||
        pareDown.options.length < 1 ||
        (pareDown.options.length === 1 && pareDown.options[0].text === '')
      ),
      selections: pareDown.selections,
      options: pareDown.options,
      setPareDownSelections: this.props.setPareDownSelections,
    };
    const groupByInputs = {
      showGroupBy: !(
        groupBy.options.length < 1 ||
        (groupBy.options.length === 1 && groupBy.options[0].text === '') ||
        !subheader.groupByDefn
      ),
      groupBy,
      onChangeGroup: this.props.onChangeGroup,
      setGroupBySelection: this.props.setGroupBySelection,
    };
    const sortByInputs = {
      showSortBy: !(
        !subheader.sortByDefn ||
        sortBy.options.length < 1 ||
        (sortBy.options.length === 1 && sortBy.options[0].text === '')
      ),
      sortBy,
      onChangeSort: this.props.onChangeSort,
      setSortBySelection: this.props.setSortBySelection,
    };
    const sortArrowInputs = {
      showSortArrow: showSortArrow(sortBy),
      onChangeSort: this.props.onChangeSort,
      setSortByDirection: this.props.setSortByDirection,
    };
    const countLimitInputs = {
      showCountLimit: this.props.showCountLimit,
      countLimit,
      countLimitOptions,
      setCountLimit: this.props.setCountLimit,
    };
    const showTitle = !this.props.hideTitle && !isEmpty(this.props.title);
    const hasActionButtons = !isNil(extraActionButtons);
    const hasLegacyActionButtons = !isNil(extraLegacyActionButtons);
    // Determine isViewLoading for favorites loading state
    const isViewLoading = Array.isArray(this.props.viewDataState)
      ? this.props.viewDataState.some((state) => state !== ViewDataState.liveDataReady)
      : this.props.viewDataState !== ViewDataState.liveDataReady;

    return (
      <div className={containerStyle} data-qa="Subheader">
        <div className="top-bar">
          <div className={classes(showTitle ? 'title-container' : 'hidden-title-container')}>
            {showTitle && <h1 className="title">{this.props.title}</h1>}
            <BackgroundDataLoading viewDataState={this.props.viewDataState || ViewDataState.idle} />
          </div>
          <div className="quick-actions">
            <section className={gridActionButtons}>
              {hasActionButtons && !isNil(extraActionButtons.massEdit) ? (
                <MassEditV2 {...extraActionButtons.massEdit} />
              ) : null}
              {hasLegacyActionButtons && !isNil(extraLegacyActionButtons.massEdit) ? (
                <MassEdit {...extraLegacyActionButtons.massEdit} />
              ) : null}
              {hasActionButtons && !isNil(extraActionButtons.postAction) && !isEmpty(extraActionButtons.postAction) ? (
                <ConfigurablePostAction {...extraActionButtons?.postAction} />
              ) : null}
            </section>
            {showUndoBtn && (
              <SubheaderQuickActionButton text={'Undo'} onClick={this.handleUndoClick} iconClass={'fa fa-undo'} />
            )}
            {configureOptions && configureOptions.type == 'enabled' && (
              <SubheaderQuickActionButton
                text={'Configure'}
                buttonDataQa={'configure-button'}
                iconClass={'far fa-cog'}
                tooltipText="Select the levels shown in this view"
                onClick={configureOptions.onConfigureClick}
              />
            )}
            {viewConfigurator &&
              viewConfigurator.updateConfig &&
              (viewConfigurator.unmodifiedViewDefn.view || viewConfigurator.unmodifiedViewDefn.columns) && (
                <ViewConfiguratorModal
                  subheaderTitle={this.props.title}
                  {...viewConfigurator}
                  ref={this.viewConfiguratorRef}
                />
              )}
            {viewConfigurator && (
              <Favorites
                viewConfigurator={viewConfigurator}
                favoriteType={favoriteType}
                favoriteDefnId={favoriteDefnId}
                favoriteOverrides={this.props.favoriteOverrides}
                favoriteViewDefnUpdater={favoriteViewDefnUpdater}
                getFavorites={this.props.getFavorites}
                setFavorites={this.props.setFavorites}
                favoritesList={subheader.favoritesList}
                favoritesSaveOverride={this.props.favoritesSaveOverride}
                getFavoritesList={this.props.getFavoritesList}
                setFavoritesList={this.props.setFavoritesList}
                subheaderValues={{
                  groupBy,
                  sortBy,
                  pareDown,
                  showCountLimit: this.props.showCountLimit,
                  countLimit,
                  countLimitOptions,
                  countLimitDefault: subheader.countLimitDefault,
                  groupByDefn: subheader.groupByDefn,
                  sortByDefn: subheader.sortByDefn,
                  pareDownDefn: subheader.pareDownDefn,
                  flowStatus: subheader.flowStatus,
                  altFlowStatus: subheader.altFlowStatus,
                  search: subheader.search,
                  altSearch: subheader.altSearch,
                }}
                subheaderHandlers={{
                  setGroupBySelection: this.props.setGroupBySelection,
                  setSortBySelection: this.props.setSortBySelection,
                  setSortByDirection: this.props.setSortByDirection,
                  setPareDownSelections: this.props.setPareDownSelections,
                  setCountLimit: this.props.setCountLimit,
                }}
                gridFilterModel={gridFilterModel}
                gridSortModel={gridSortModel}
                gridColumnState={gridColumnState}
                resetGridModel={resetGridModel}
                onFavoriteSave={this.onFavoriteSave}
                isViewLoading={isViewLoading}
              />
            )}
            {this.props.downloadLink && (
              <SubheaderQuickActionButton
                iconClass={'far fa-cloud-download'}
                iconDataQa="subheader-download-button"
                href={generateDownloadLinkWithToken(this.props.downloadLink)}
              />
            )}
          </div>
          <section className={classes('filters', getFiltersStyle(true))}>
            {activeCrumbPath && (
              <Link
                className="breadcrumb-link"
                onClick={() => {
                  this.props.activeBreadcrumb ? resetCrumbs!(this.props.activeBreadcrumb) : noop();
                  window.history.back();
                }}
              >
                <i className="fa fa-caret-left" aria-hidden="true"></i>Back to{' '}
                {getViewNameFromPath(nth(activeCrumbPath, -2)!)}
              </Link>
            )}
            <SubheaderFilters
              flowStatusInputs={flowStatusInputs}
              altFlowStatusInputs={altFlowStatusInputs}
              searchInputs={searchInputs}
              altSearchInputs={altSearchInputs}
              customElement={this.props.customEl}
              pareDownInputs={pareDownInputs}
              groupByInputs={groupByInputs}
              sortByInputs={sortByInputs}
              sortArrowInputs={sortArrowInputs}
              extraDropdownElements={extraDropdownsElements}
              countLimitInputs={countLimitInputs}
              showDateRange={showDateRange}
            />
          </section>
        </div>
        <BottomBar
          errorCondition={this.props.errorCondition}
          lookBackPeriod={subheader.lookBackPeriod}
          lookBackPeriods={this.props.lookBackPeriods}
          showLookBackPeriod={this.props.showLookBackPeriod}
          summary={this.props.summary}
          updateLookBackPeriod={this.props.updateLookBackPeriod}
        />
      </div>
    );
  }
}
