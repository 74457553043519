import {
  AxisLabelsFormatterContextObject,
  AxisOptions,
  Point,
  Series,
  SeriesBarOptions,
  SeriesLineOptions,
  SeriesOptions,
  SeriesClickEventObject,
} from 'highcharts';
import { defaultTo, flatMap, get, isArray } from 'lodash';
import { Renderer } from 'src/common-ui/components/DataGrid/Renderer';
import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, Pivot } from 'src/worker/pivotWorker.types';

export const axesDefaults: AxisOptions = {
  title: undefined,
  minorGridLineWidth: 0,
  lineColor: 'transparent',
  minorTickLength: 0,
  tickLength: 0,
  labels: {
    style: {
      fontSize: '14px',
    },
  },
};

interface ColorsOnTrue {
  foreground?: string;
  background?: string;
  left?: string;
  right?: string;
}

export interface DilineateOption {
  key: string;
  value: string;
  colorsOnTrue: ColorsOnTrue;
  colorsOnFalse: ColorsOnTrue;
}

export function generateSecondaryGraphConfig(
  { text, dataIndex, renderer }: TenantConfigViewItem,
  data: Pivot
): SeriesLineOptions {
  return {
    type: 'line',
    name: text,
    tooltip: {
      headerFormat: '',
      pointFormatter: function (this: Point) {
        const renderedPointValue = renderer ? Renderer[renderer](this.y) : this.y;
        return `<b>${renderedPointValue}</b>`;
      },
    },
    data: data.tree.map((x) => (x[dataIndex] ? x[dataIndex] : 0)),
  };
}

export function generateLineGraphConfig(
  xAxisLabels: string[],
  dataUnfiltered: number[][][],
  config: TenantConfigViewData,
  selectedButtonIndex: number,
  height: number,
  onPointClick: (event: SeriesClickEventObject) => void
): Highcharts.Options {
  // TODO: remove all projection from here and make it so that it just takes finished objects
  // this sets up metric pairs as axes, defaulting all but the first to visible: false
  const data = dataUnfiltered[selectedButtonIndex];
  const graphs = config.graphs;
  const dilineate = config.dilineate;
  if (!graphs) return {};
  const viewDefn = graphs.primary.filter((_opt, idx) => idx === selectedButtonIndex);
  const yAxes = flatMap(
    viewDefn.filter((v) => v.left && v.right),
    (view) => {
      // TODO: maybe do something with the tick lines?
      const axesPair: AxisOptions[] = [
        {
          ...axesDefaults,
          title: {
            text: view.left?.text || null,
          },
          opposite: false,
          visible: view.left?.visible,
        },
        {
          ...axesDefaults,
          title: {
            text: view.right?.text || null,
          },
          opposite: true,
          visible: view.right?.visible,
        },
      ];
      // special axes stuff for percents
      if (view.left?.renderer === 'percent') {
        axesPair[0].max = 100;
        axesPair[0].min = 0;
      }
      if (view.right?.renderer === 'percent') {
        axesPair[1].max = 100;
        axesPair[1].min = 0;
      }
      return axesPair;
    }
  );

  const getZones = (dilineate: DilineateOption | undefined, data: any[], dir: string) => {
    if (dilineate && dilineate.key && data) {
      const indexesTrue: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] === dilineate.value ? a.concat(i) : a),
        []
      );
      const indexesFalse: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] !== dilineate.value ? a.concat(i) : a),
        []
      );
      let zones = [
        {
          value: indexesFalse[0] + 1,
          color: dilineate.colorsOnFalse
            ? dilineate.colorsOnFalse[dir] || dilineate.colorsOnFalse.foreground
            : undefined,
        },
        {
          value: indexesFalse[indexesFalse.length - 1],
          color: dilineate.colorsOnFalse
            ? dilineate.colorsOnFalse[dir] || dilineate.colorsOnFalse.foreground
            : undefined,
        },
      ];
      if (indexesTrue.length > 0) {
        zones = zones
          .concat([
            {
              value: indexesTrue[0],
              color: dilineate.colorsOnTrue
                ? dilineate.colorsOnTrue[dir] || dilineate.colorsOnTrue.foreground
                : undefined,
            },
            {
              value: indexesTrue[indexesTrue.length - 1] + 1,
              color: dilineate.colorsOnTrue
                ? dilineate.colorsOnTrue[dir] || dilineate.colorsOnTrue.foreground
                : undefined,
            },
          ])
          .sort((x, y) => x.value - y.value);
        return zones;
      }
    }
    return undefined;
  };
  const getPlotbands = (dilineate: DilineateOption | undefined, data: any[]) => {
    if (dilineate && dilineate.key && data) {
      const indexesTrue: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] === dilineate.value ? a.concat(i) : a),
        []
      );
      const indexesFalse: number[] = data.reduce(
        (a, e, i) => (e[dilineate.key] !== dilineate.value ? a.concat(i) : a),
        []
      );
      let plotbands = [
        {
          color: get(dilineate, 'colorsOnFalse.background'),
          from: indexesFalse[0],
          to: indexesFalse[indexesFalse.length - 1],
        },
      ];
      if (indexesTrue.length > 0) {
        plotbands = plotbands.concat([
          {
            color: get(dilineate, 'colorsOnTrue.background'),
            from: indexesTrue[0],
            to: indexesTrue[indexesTrue.length - 1] + 1,
          },
        ]);
      }
      return plotbands;
    }
    return undefined;
  };
  const series: (SeriesLineOptions | SeriesBarOptions)[] = ['left', 'right']
    .filter((side) => {
      const config = viewDefn[0][side] as TenantConfigViewItem;
      const visible = defaultTo(config.visible, true); // if left or right is an array, `visible` doesn't work
      return visible;
    })
    .flatMap((key, axisIndex) => {
      const config = viewDefn[0][key] as TenantConfigViewItem;
      if (!isArray(config)) {
        return {
          boostThreshold: 1,
          type: (config?.chartType || 'line') as 'line' | 'bar',
          name: config?.text || viewDefn[0].left?.dataIndex,
          description: config?.text, // used for screenreaders only
          data: data[axisIndex],
          color: config?.color || undefined,
          tooltip: {
            pointFormatter: function (this: Point) {
              const seriesName = this.series.name;
              const category = this.category;
              const renderedPointValue = config?.renderer ? Renderer[config.renderer](this.y) : this.y;
              return `${category} <br /> ${seriesName} : <b>${renderedPointValue}</b>`;
            },
          },
          zones: getZones(dilineate, data[0], key),
          zoneAxis: 'x',
          point: {
            events: {
              click: function (event) {
                onPointClick(event);
              },
            },
          },
        };
      }
      return config.map((graph, seriesIndex) => {
        return {
          type: (graph?.chartType || 'line') as 'line' | 'bar',
          name: graph?.text || viewDefn[0].left?.dataIndex,
          description: graph?.text, // used for screenreaders only
          data: (data[axisIndex][seriesIndex] as unknown) as number[],
          color: graph?.color || undefined,
          tooltip: {
            pointFormatter: function (this: Point) {
              const seriesName = this.series.name;
              const category = this.category;
              const renderedPointValue = graph?.renderer ? Renderer[graph.renderer](this.y) : this.y;
              return `${category} <br /> ${seriesName} : <b>${renderedPointValue}</b>`;
            },
          },
          zones: getZones(dilineate, data[0], key),
          zoneAxis: 'x',
          point: {
            events: {
              click: function (event) {
                onPointClick(event);
              },
            },
          },
        };
      });
    });

  return {
    credits: { enabled: false },
    chart: {
      height: `${height}px`,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
      text: '',
    },
    yAxis: yAxes,

    xAxis: {
      categories: xAxisLabels,
      title: {
        text: '',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject) {
          // TODO: map week ids to names here?
          return `<span style="font-style: italic;">${this.value}</span>`;
        },
      },
      plotBands: getPlotbands(dilineate, data[1]),
    },
    series: series,
  };
}

export function generateBarGraphConfig(selectedBarGraphItem: number, series: SeriesOptions[]): Highcharts.Options {
  return {
    credits: { enabled: false },
    title: {
      text: '',
    },
    chart: {
      type: 'line',
      height: '120px',
    },
    xAxis: {
      title: {
        text: '',
      },
      minorGridLineWidth: 0,
      minorTickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: 0,
      min: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        //pointPadding: 20,
        //borderWidth: 0,
        //pointWidth: 10,
        color: TEAL_PRIMARY,
      },
      series: {
        color: 'black',
        events: {
          legendItemClick: function (this: Series): boolean | void {
            this.chart.series.forEach((seri) => {
              if (seri !== this && seri.visible) {
                seri.hide();
              }
            });
            return !this.visible ? true : false;
          },
        },
      },
    },
    // @ts-ignore
    series: series.filter((x, i) => i == selectedBarGraphItem),
  };
}

export function formatHighlightMetricsData(): BasicPivotItem[] {
  return ([
    {
      __weeks: 18,
      __grossmargin: 0.52,
      __slsr: 85000,
    },
  ] as any[]) as BasicPivotItem[];
}
