import React from 'react';
import { useEffect, useRef, useCallback, createContext, useMemo, useContext } from 'react';
import { connect } from 'react-redux';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DraggablePointsModule from 'highcharts/modules/draggable-points';

import { EditableChartProps } from './EditableChart.types';
import { CELL_TAG_DISABLED } from 'src/pivot/PivotCell';
import {
  chartConfigToChartOptions,
  getChartData,
  mergeTimeAndChartData,
  defaultChartOptions,
  useHandleChangeMetric,
  useMemoDropdownMetrics,
  useSelectedMetric,
} from './EditableChart.utils';
import { EditableChartValueProps, EditableChartDispatchProps, EditableChartOwnProps } from './EditableChart.types';
import { mapStateToProps, mapDispatchToProps } from './EditableChart.container';
import { AppState } from 'src/store';

import { Lens } from 'monocle-ts';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import './_EditableChart.scss';
import { isScopeReady } from 'src/state/scope/Scope.types';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import PivotManager from 'src/pivot/Pivot.client';
import { refIsNotNull } from 'src/utils/Component/component.utils';
import { buildChartPivotManager } from './EditableChart.epics';
import ServiceContainer from 'src/ServiceContainer';

DraggablePointsModule(Highcharts); // Attach the draggable points module

export const seriesColors = ['#7bb5ec', '#91e8e0'];
export const markerColors = ['#52799e', '#008076'];
export const markerFillColors = ['#6595c2', '#00a89b'];

const defaultDropdownProps: DropdownProps = {
  search: true,
  scrolling: true,
  selection: true,
};

type HighChartsRefObject = {
  /**
   * Chart reference
   */
  chart: Highcharts.Chart;

  /**
   * React reference
   */
  container: React.RefObject<HTMLDivElement>;
};
const ChartPivotContext = createContext<PivotManager | undefined>(undefined);
const refLens = Lens.fromNullableProp<React.RefObject<HighChartsRefObject>>()('current', null);
const chartLens = refLens.compose(Lens.fromProp<HighChartsRefObject>()('chart'));

const EditableChartHooks = (props: EditableChartProps) => {
  const {
    scopeId,
    onCompleteRefreshChart,
    requestChart,
    chartDimensionConfig,
    mainConfig,
    forceRefreshChart,
    setChartDataStale,
    viewParams,
    settings,
  } = props;
  const client = ServiceContainer.axios;

  const chartRef = useRef<HighChartsRefObject | null>(null);

  const dropdownMetrics: DropdownItemProps[] | undefined = useMemoDropdownMetrics(mainConfig);

  const changeLeftMetric = useHandleChangeMetric(requestChart, chartDimensionConfig, 0);
  const changeRightMetric = useHandleChangeMetric(requestChart, chartDimensionConfig, 1);

  const selectedLeftMetric = useSelectedMetric(0, dropdownMetrics, chartDimensionConfig);
  const selectedRightMetric = useSelectedMetric(1, dropdownMetrics, chartDimensionConfig);

  useEffect(() => {
    if (viewParams) {
      requestChart({
        metricDimensions: viewParams.rows[0],
        xAxisDimension: viewParams.columns[0],
        versions: viewParams.rows[1],
      });
    }
  }, [requestChart, viewParams]);

  const chartPivotManager: PivotManager | undefined = useMemo(() => {
    if (chartDimensionConfig && scopeId && mainConfig && isScopeReady(mainConfig) && settings) {
      return buildChartPivotManager(mainConfig, settings, chartDimensionConfig, scopeId, client);
    }
  }, [chartDimensionConfig, client, mainConfig, scopeId, settings]);

  const updatePoint = useCallback(
    (event: Highcharts.PointDropEventObject) => {
      if (chartPivotManager && event.newPointId) {
        const newPoint = event.newPoints[event.newPointId];
        if (!newPoint || !newPoint.newValues.y) {
          return;
        }
        const xAxisValue = newPoint.point.x;

        const metricId = newPoint.point.series.options.id;
        if (!metricId) {
          throw new Error('chart metric id not found, this shouldnt happen');
        }

        const revCount = chartPivotManager.config.getRowGroups()[1].getVisible().length;
        const metricRowIndex = chartPivotManager.config
          .getRowGroups()[0]
          .getVisible()
          .findIndex((d) => d.member.id === metricId);

        // TODO: remove this hardcoded location
        chartPivotManager
          .updateCell(metricRowIndex * revCount, xAxisValue, newPoint.newValues.y.toString())
          .then(() => {
            // TODO: put the chart into some kind of indeterminate state to indicate that data is loading
            // the pivot goes dirty on it's own,
            // and then the refresh call comes down from state.forceRefreshChart
          })
          .catch((_err) => {
            setChartDataStale();
            // props.serviceEnv.loggingService.error('Something went wrong trying to save to the grid');
            // props.onUpdateGridAsyncState(GRID_ERROR);
          });
      }
    },
    [chartPivotManager, setChartDataStale]
  );

  const chartOptions: Highcharts.Options = useMemo(() => {
    if (refIsNotNull(chartRef) && mainConfig && isScopeReady(mainConfig) && chartDimensionConfig) {
      return chartConfigToChartOptions(chartDimensionConfig, mainConfig, chartOptions, updatePoint);
    }
    return defaultChartOptions;
  }, [chartDimensionConfig, mainConfig, updatePoint]);

  useEffect(() => {
    // get new data and set it into the chart
    if (refIsNotNull(chartRef) && chartRef.current && chartDimensionConfig && chartPivotManager && forceRefreshChart) {
      getChartData(chartPivotManager).then((chartData) => {
        const chart = chartRef.current && chartLens.get(chartRef);
        if (chartData && chart) {
          const versionsCount = chartDimensionConfig.versions.items.length;

          // find the first uneditable time index and set the zones based on that
          const currentTimeIndex = chartData
            .filter((pc) => pc.row === 0)
            .findIndex((pc) => !pc.tags.includes(CELL_TAG_DISABLED));

          chart.series.forEach((series, idx) => {
            series.setData(chartData.filter((cell) => cell.row === idx * versionsCount).map(mergeTimeAndChartData));
            if (series.type === 'line' && series.options.type === 'line') {
              series.update({
                ...series.options,
                type: 'line',
                zones: [
                  {
                    value: currentTimeIndex, // TODO: this will have to be the index of the last elapsed x-axis value
                    dashStyle: 'Solid',
                  },
                  {
                    dashStyle: 'ShortDot',
                  },
                ],
              });
            }
          });
        }
        onCompleteRefreshChart();
      });
    }
  }, [chartDimensionConfig, chartPivotManager, chartRef, forceRefreshChart, onCompleteRefreshChart]);

  if (!scopeId || chartDimensionConfig === null) {
    return <LoadingMask />;
  } // TODO: better loading render
  return (
    <div className={'chart'}>
      <ChartPivotContext.Provider value={chartPivotManager}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          // the middle 'false' below force redraws the axis, series, and legend
          // see https://api.highcharts.com/class-reference/Highcharts.Chart#
          updateArgs={[true, true, true]}
          // source ref type here is hardcoded to LegacyRef
          // @ts-ignore
          ref={chartRef}
        />
        <div className={'chart-bottom'}>
          <Dropdown
            {...defaultDropdownProps}
            id={'0'}
            key={'0'}
            className={'left-dimension dimension-select'}
            value={selectedLeftMetric}
            options={dropdownMetrics}
            onChange={changeLeftMetric}
          />
          <Dropdown
            {...defaultDropdownProps}
            id={'1'}
            key={'1'}
            className={'right-dimension dimension-select'}
            value={selectedRightMetric}
            options={dropdownMetrics}
            onChange={changeRightMetric}
          />
        </div>
      </ChartPivotContext.Provider>
    </div>
  );
};

export default connect<EditableChartValueProps, EditableChartDispatchProps, EditableChartOwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(EditableChartHooks);
