import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

export default {
  dialogBody: style({
    width: 500,
    height: '80vh',
    marginBottom: 100,
    marginTop: 10,
  }),
  cropperContainer: style({
    height: '100%',
    width: '100%',
  }),
  buttonContainer: style({
    bottom: 0,
    width: '100%',
    height: '15%',
    marginBottom: 10,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
    alignItems: 'center',
  }),
  buttonSave: style({
    width: 80,
    background: TEAL_PRIMARY,
    color: 'white',
    height: 30,
    cursor: 'pointer',
    margin: 5,
  }),
  titleContainer: style({
    height: '15%',
    width: '100%',
    padding: 15,
    marginBottom: 10,
    backgroundColor: TEAL_PRIMARY,
    color: 'white',
    $nest: {
      'input-file-container': {
        marginBottom: 10,
      },
    },
  }),
  buttonCancel: style({
    position: 'absolute',
    right: 10,
    top: 13,
    fontWeight: 600,
    cursor: 'pointer',
  }),
  title: style({
    fontSize: '1.2rem',
    fontWeight: 600,
  }),
  buttonText: style({
    top: 2,
    position: 'relative',
    fontSize: '.9rem',
  }),
  dragActive: style( {
    border: '3px dashed #01a99b',
    backgroundColor: '#f0f0f0'

  }),
  dropContainer: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  })
};
