import React from 'react';
import { IconButton, Menu, MenuItem, Divider, Tooltip, PopoverProps } from '@material-ui/core';
import { favoritesList, deleteButtonStyle, favoritesNameStyle } from './Favorites.styles';
import SubheaderQuickActionButton from '../SubheaderQuickActionButton';
import {
  DEFAULT_FAVORITE,
  DEFAULT_FAVORITE_ID,
  DEFAULT_FAVORITE_RESPONSE,
  FavoriteListItem,
  FavoriteResponseItem,
} from 'src/components/Subheader/Favorites/Favorites.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { CompanionFavoriteData } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

const propToIcon = (isFavoriteActive: Pick<FavoritesButtonProps, 'isFavoriteActive'>) => {
  return isFavoriteActive ? 'fa fa-heart' : 'far fa-heart';
};

interface FavoritesButtonProps {
  /** Determines if a user's favorite is currently selected */
  isFavoriteActive: boolean;
  onClick: (elem: EventTarget & HTMLElement) => void;
  isLoading?: boolean;
}

export const FavoritesButton = (props: FavoritesButtonProps) => {
  return (
    <SubheaderQuickActionButton
      defaultsPresent={!props.isFavoriteActive}
      iconClass={propToIcon(props)}
      iconDataQa="subheader-favorites-button"
      tooltipText="Create, update, or delete favorites for this view"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        props.onClick(event.currentTarget);
      }}
      isLoading={props.isLoading}
    />
  );
};

const FavoriteMenuItem = (
  favorite: FavoriteResponseItem,
  favIdx: number,
  onApplyFavorite: (favItem: FavoriteListItem, key: string) => void,
  onClickDelete: (favItem: FavoriteListItem) => void
) => {
  const fav = favorite.jsonBlob;
  if (!fav) return;
  const showTooltip = fav.name.length > 15;
  const name = showTooltip ? (
    <Tooltip title={fav.name} placement="top">
      <span className={favoritesNameStyle}>{fav.name}</span>
    </Tooltip>
  ) : (
    fav.name
  );
  return (
    <MenuItem
      id={fav.id}
      key={favIdx}
      data-qa={`subheader-favorites-button-${favIdx}`}
      className={'favorite-menu-item'}
      onClick={() => onApplyFavorite(fav, favorite.key)}
      selected={favorite.active}
    >
      {fav.icon ? <i className={fav.icon} /> : <i className={'fab fa-gratipay'} />}
      {name}
      {fav.id !== DEFAULT_FAVORITE_ID && (
        <IconButton
          aria-label="favorite-delete"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onClickDelete(fav);
          }}
          style={deleteButtonStyle}
          className={'sizeSmall'}
        >
          <i className={'far fa-trash'} data-qa="favorite-trash-button" />
        </IconButton>
      )}
    </MenuItem>
  );
};

interface FavoritesListProps extends Pick<PopoverProps, 'anchorEl'> {
  favItems: FavoriteResponseItem[];
  open: boolean;
  unmodifiedViewDefn: TenantConfigViewData;
  defaultCompanionData?: CompanionFavoriteData;
  onClose: (event: React.SyntheticEvent<Record<string, any>>) => void;
  onClickSave: (event: React.SyntheticEvent<Record<string, any>>) => void;
  onClickDelete: (favItem: FavoriteListItem) => void;
  // FIXME: with new favorites improvements, `key` is no longer needed
  onApplyFavorite: (favItem: FavoriteListItem, key: string) => void;
}

export const FavoritesList = (props: FavoritesListProps) => {
  // Cap favorites to 5 (+1 for default view)
  const disableFav = props.favItems.length > 4;
  const toolTipMsg = disableFav ? 'You cannot save a new favorite until you delete an old one' : '';

  return (
    <Menu
      id="favorites-list"
      className={favoritesList}
      data-qa={'subheader-favorites-list'}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted={true}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      {props.favItems
        .concat([
          {
            ...DEFAULT_FAVORITE_RESPONSE,
            jsonBlob: DEFAULT_FAVORITE(props.unmodifiedViewDefn, props.defaultCompanionData),
          },
        ])
        .map((item, i) => FavoriteMenuItem(item, i, props.onApplyFavorite, props.onClickDelete))}
      <div>
        <Divider />
        <Tooltip title={toolTipMsg}>
          <span>
            <MenuItem
              key={'save-fav'}
              data-qa="subheader-favorites-button-save-new"
              className={'favorite-menu-item'}
              onClick={props.onClickSave}
              disabled={disableFav}
            >
              <i className={'fa fa-save'} />
              Save new Favorite
            </MenuItem>
          </span>
        </Tooltip>
      </div>
    </Menu>
  );
};
