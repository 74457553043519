import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import { flatten, isEmpty } from 'lodash/fp';

import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { Indexable } from 'src/types/Primitive';
import styles, { fullCircleWithColor } from './ParetoSummary.styles';
import { NoRowsOverlay } from 'src/components/NoRowsOverlay/NoRowsOverlay';
import { buildCurrentSeries, buildChartConfig } from './ParetoSummary.utils';
import Highcharts from 'highcharts';

interface ColumnNames {
  text: string;
}

interface ColumnItem {
  renderer: string;
  label?: string;
  variance?: string;
}

export interface ParetoSummaryPivot {
  columnItems?: ColumnItem[];
  columnNames?: ColumnNames[];
  quintiles: Indexable[];
  top: Indexable;
  topLeft?: TenantConfigViewItem[];
  topRight?: TenantConfigViewItem[];
  graphConfig?: TenantConfigViewItem[];
  total: Indexable;
}

interface ParetoSummaryProps extends ParetoSummaryPivot {
  summaryGraphData: number[];
}

export default class ParetoSummary extends React.Component<ParetoSummaryProps> {
  constructor(props: ParetoSummaryProps) {
    super(props);
  }

  render() {
    const { columnNames, summaryGraphData } = this.props;
    const graph = this.props.graphConfig as TenantConfigViewItem[];
    const topLeft = this.props.topLeft as TenantConfigViewItem[];
    const topRight = this.props.topRight as TenantConfigViewItem[];
    const columnItems = this.props.columnItems as ColumnItem[];
    const chartConfig = buildChartConfig(graph);
    const series = buildCurrentSeries(summaryGraphData);

    if (isEmpty(flatten(topLeft)) && isEmpty(flatten(topRight)) && isEmpty(flatten(columnItems))) {
      return <NoRowsOverlay />;
    }

    function renderTopLeftItems(items: TenantConfigViewItem[]) {
      return items.map((metrics: TenantConfigViewItem, index: number) => {
        const primary = metrics[0];
        const lastYear = metrics[2];
        const variance = metrics[3];
        return (
          <div className="item" key={index}>
            <div className="primary">
              {primary.label}: {primary.renderer}
            </div>
            <div className="secondary">
              {lastYear.label} {lastYear.renderer} {variance.label} {variance.renderer}
            </div>
          </div>
        );
      });
    }

    function renderTopRightItems(items: TenantConfigViewItem[]) {
      return items.map((metrics: TenantConfigViewItem, index: number) => {
        const primary = metrics[0];
        const lastYear = metrics[2];
        return (
          <div className="item" key={index}>
            <div className="section-piece combo percent">
              <div className="percentage">{primary.renderer}</div>
            </div>
            <div className="section-piece combo">
              <div className="main">{primary.label}</div>
              <div className="secondary">
                {lastYear.label} {lastYear.renderer}
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className={styles.paretoSummary}>
        <div className="info-graph-widget">
          <div className="key-statistics">
            <div className="section-title">Key Statistics</div>
            <div className="bottom">
              <div className="left">{renderTopLeftItems(topLeft)}</div>
              <div className="center">
                <HighchartsReact highcharts={Highcharts} options={{ ...chartConfig, series }} />
              </div>
              <div className="right">
                <div className="item">
                  <div className="section-piece">Contributions from</div>
                  <div className="section-piece main">Top 50% CCs</div>
                </div>
                {renderTopRightItems(topRight)}
              </div>
            </div>
          </div>
          <div className="info-graph-bottom">
            <div className="title-row">
              <span className="section-title">Percentage Contribution</span>
            </div>
            <div className="quintiles">
              <div className="section-column-names">
                <div className="left" />
                {columnNames &&
                  columnNames.map((title) => (
                    <div key={`pareto-summary-column-${title}`} className="column">
                      {title}
                    </div>
                  ))}
                <div className="right" />
              </div>
              <div className="row-container">
                {columnItems.map((metrics, index) => {
                  const sectionNames = ['Top 20%', 'Above Average', 'Average', 'Below Average', 'Bottom 20%'];
                  const firstCol = metrics[0];
                  const firstColThisYear = firstCol[1];
                  const firstColLastYear = firstCol[2];
                  const firstColVariance = firstCol[3];

                  const secondAndThirdColumns = [metrics[1], metrics[2]];
                  return (
                    <div
                      key={index}
                      className={`detail-row ${index === 0 || index === 4 ? 'highlight' : ''}`}
                      data-qa={sectionNames[index]}
                    >
                      <div className="left">
                        <div className="title">{sectionNames[index]}</div>
                        <div className="section-summary">
                          <span className="secondary">Count:</span>
                          <span className="secondary">
                            {firstColThisYear.label}: {firstColThisYear.renderer}
                          </span>
                          <span className="secondary">
                            {firstColLastYear.label}: {firstColLastYear.renderer}
                          </span>
                          <span className="secondary">
                            {firstColVariance.label}: {firstColVariance.renderer}
                          </span>
                        </div>
                      </div>
                      {secondAndThirdColumns.map((column, idx) => {
                        const Primary = column[0];
                        const ThisYear = column[1];
                        const LastYear = column[2];
                        const Variance = column[3];
                        return (
                          <div className="column" key={idx}>
                            <div className="details">
                              <div className="circle-container" data-qa={columnNames && columnNames[idx]}>
                                <div className={fullCircleWithColor(Primary.variance)} data-qa="circle">
                                  {Primary.renderer}
                                </div>
                                <div className="circle-desc">
                                  <div className="bottom-text">
                                    {ThisYear.label}: {ThisYear.renderer}
                                  </div>
                                  <div className="bottom-text">
                                    {LastYear.label}: {LastYear.renderer}
                                  </div>
                                  <div className="bottom-text" data-qa="variance">
                                    {Variance.label}: {Variance.renderer}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="right" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
